import { template as template_e9c8cc76261845daa2e78bb0f775fd9c } from "@ember/template-compiler";
const WelcomeHeader = template_e9c8cc76261845daa2e78bb0f775fd9c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
