import { template as template_e1ad20c5b01945fbbf088e24874d62eb } from "@ember/template-compiler";
const FKControlMenuContainer = template_e1ad20c5b01945fbbf088e24874d62eb(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
