import { template as template_e67d84c569044c8ea1314ddb39bb30dd } from "@ember/template-compiler";
const FKText = template_e67d84c569044c8ea1314ddb39bb30dd(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
