import { template as template_40a22f9560c74aa781f516336ad1a1eb } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_40a22f9560c74aa781f516336ad1a1eb(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
